<template>
	<TagFilterForm
		:is-submitting="create.isCreating"
		:is-slug-duplicate="create.fieldErrors.slug"
		:is-available-product-error="create.fieldErrors.availableProduct"
		@onSubmit="handleSubmit"
	/>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import TagFilterForm from '@/components/TagFilterForm.vue';
import { ROUTE_NAME } from '../enums/route';

export default {
	name: 'TagFilterCreate',

	components: {
		TagFilterForm,
	},

	computed: {
		...mapState('tagFilters', {
			create: 'create',
		}),
	},

	methods: {
		...mapActions({
			createTagFilter: 'tagFilters/createTagFilter',
		}),
		async handleSubmit(formData) {
			try {
				await this.createTagFilter(formData);
				this.$router.push({ name: ROUTE_NAME.TAG_FILTER_LIST });
			} catch (error) {
				// Do nothing
			}
		},
	},
};
</script>
